import { combine, isTelemetryReplicationAllowed, startBatchWithReplica } from '@datadog/browser-core';
export function startRumBatch(configuration, lifeCycle, telemetryEventObservable, reportError, pageExitObservable, sessionExpireObservable, createEncoder) {
  const replica = configuration.replica;
  const batch = startBatchWithReplica(configuration, {
    endpoint: configuration.rumEndpointBuilder,
    encoder: createEncoder(2 /* DeflateEncoderStreamId.RUM */)
  }, replica && {
    endpoint: replica.rumEndpointBuilder,
    transformMessage: message => combine(message, {
      application: {
        id: replica.applicationId
      }
    }),
    encoder: createEncoder(3 /* DeflateEncoderStreamId.RUM_REPLICA */)
  }, reportError, pageExitObservable, sessionExpireObservable);
  lifeCycle.subscribe(13 /* LifeCycleEventType.RUM_EVENT_COLLECTED */, serverRumEvent => {
    if (serverRumEvent.type === "view" /* RumEventType.VIEW */) {
      batch.upsert(serverRumEvent, serverRumEvent.view.id);
    } else {
      batch.add(serverRumEvent);
    }
  });
  telemetryEventObservable.subscribe(event => batch.add(event, isTelemetryReplicationAllowed(configuration)));
  return batch;
}
