import { sendToExtension, createPageExitObservable, startTelemetry, canUseEventBridge, getEventBridge, addTelemetryDebug, drainPreStartTelemetry } from '@datadog/browser-core';
import { createDOMMutationObservable } from '../browser/domMutationObservable';
import { createWindowOpenObservable } from '../browser/windowOpenObservable';
import { startRumAssembly } from '../domain/assembly';
import { startInternalContext } from '../domain/contexts/internalContext';
import { LifeCycle } from '../domain/lifeCycle';
import { startViewHistory } from '../domain/contexts/viewHistory';
import { startRequestCollection } from '../domain/requestCollection';
import { startActionCollection } from '../domain/action/actionCollection';
import { startErrorCollection } from '../domain/error/errorCollection';
import { startResourceCollection } from '../domain/resource/resourceCollection';
import { startViewCollection } from '../domain/view/viewCollection';
import { startRumSessionManager, startRumSessionManagerStub } from '../domain/rumSessionManager';
import { startRumBatch } from '../transport/startRumBatch';
import { startRumEventBridge } from '../transport/startRumEventBridge';
import { startUrlContexts } from '../domain/contexts/urlContexts';
import { createLocationChangeObservable } from '../browser/locationChangeObservable';
import { startFeatureFlagContexts } from '../domain/contexts/featureFlagContext';
import { startCustomerDataTelemetry } from '../domain/startCustomerDataTelemetry';
import { startPageStateHistory } from '../domain/contexts/pageStateHistory';
import { startDisplayContext } from '../domain/contexts/displayContext';
import { startVitalCollection } from '../domain/vital/vitalCollection';
import { startCiVisibilityContext } from '../domain/contexts/ciVisibilityContext';
import { startLongAnimationFrameCollection } from '../domain/longAnimationFrame/longAnimationFrameCollection';
import { RumPerformanceEntryType } from '../browser/performanceObservable';
import { startLongTaskCollection } from '../domain/longTask/longTaskCollection';
export function startRum(configuration, recorderApi, customerDataTrackerManager, getCommonContext, initialViewOptions, createEncoder,
// `startRum` and its subcomponents assume tracking consent is granted initially and starts
// collecting logs unconditionally. As such, `startRum` should be called with a
// `trackingConsentState` set to "granted".
trackingConsentState, customVitalsState) {
  var _a;
  const cleanupTasks = [];
  const lifeCycle = new LifeCycle();
  lifeCycle.subscribe(13 /* LifeCycleEventType.RUM_EVENT_COLLECTED */, event => sendToExtension('rum', event));
  const telemetry = startRumTelemetry(configuration);
  telemetry.setContextProvider(() => {
    var _a, _b;
    return {
      application: {
        id: configuration.applicationId
      },
      session: {
        id: (_a = session.findTrackedSession()) === null || _a === void 0 ? void 0 : _a.id
      },
      view: {
        id: (_b = viewHistory.findView()) === null || _b === void 0 ? void 0 : _b.id
      },
      action: {
        id: actionContexts.findActionId()
      }
    };
  });
  const reportError = error => {
    lifeCycle.notify(14 /* LifeCycleEventType.RAW_ERROR_COLLECTED */, {
      error
    });
    addTelemetryDebug('Error reported to customer', {
      'error.message': error.message
    });
  };
  const featureFlagContexts = startFeatureFlagContexts(lifeCycle, customerDataTrackerManager.getOrCreateTracker(0 /* CustomerDataType.FeatureFlag */));
  const pageExitObservable = createPageExitObservable(configuration);
  const pageExitSubscription = pageExitObservable.subscribe(event => {
    lifeCycle.notify(11 /* LifeCycleEventType.PAGE_EXITED */, event);
  });
  cleanupTasks.push(() => pageExitSubscription.unsubscribe());
  const session = !canUseEventBridge() ? startRumSessionManager(configuration, lifeCycle, trackingConsentState) : startRumSessionManagerStub();
  if (!canUseEventBridge()) {
    const batch = startRumBatch(configuration, lifeCycle, telemetry.observable, reportError, pageExitObservable, session.expireObservable, createEncoder);
    cleanupTasks.push(() => batch.stop());
    startCustomerDataTelemetry(configuration, telemetry, lifeCycle, customerDataTrackerManager, batch.flushObservable);
  } else {
    startRumEventBridge(lifeCycle);
  }
  const domMutationObservable = createDOMMutationObservable();
  const locationChangeObservable = createLocationChangeObservable(configuration, location);
  const pageStateHistory = startPageStateHistory(configuration);
  const {
    observable: windowOpenObservable,
    stop: stopWindowOpen
  } = createWindowOpenObservable();
  cleanupTasks.push(stopWindowOpen);
  const {
    viewHistory,
    urlContexts,
    actionContexts,
    addAction,
    stop: stopRumEventCollection
  } = startRumEventCollection(lifeCycle, configuration, location, session, pageStateHistory, locationChangeObservable, domMutationObservable, featureFlagContexts, windowOpenObservable, getCommonContext, reportError);
  cleanupTasks.push(stopRumEventCollection);
  drainPreStartTelemetry();
  const {
    addTiming,
    startView,
    setViewName,
    setViewContext,
    setViewContextProperty,
    getViewContext,
    stop: stopViewCollection
  } = startViewCollection(lifeCycle, configuration, location, domMutationObservable, windowOpenObservable, locationChangeObservable, pageStateHistory, recorderApi, initialViewOptions);
  cleanupTasks.push(stopViewCollection);
  const {
    stop: stopResourceCollection
  } = startResourceCollection(lifeCycle, configuration, pageStateHistory);
  cleanupTasks.push(stopResourceCollection);
  if (configuration.trackLongTasks) {
    if ((_a = PerformanceObserver.supportedEntryTypes) === null || _a === void 0 ? void 0 : _a.includes(RumPerformanceEntryType.LONG_ANIMATION_FRAME)) {
      const {
        stop: stopLongAnimationFrameCollection
      } = startLongAnimationFrameCollection(lifeCycle, configuration);
      cleanupTasks.push(stopLongAnimationFrameCollection);
    } else {
      startLongTaskCollection(lifeCycle, configuration);
    }
  }
  const {
    addError
  } = startErrorCollection(lifeCycle, configuration, pageStateHistory);
  startRequestCollection(lifeCycle, configuration, session);
  const vitalCollection = startVitalCollection(lifeCycle, pageStateHistory, customVitalsState);
  const internalContext = startInternalContext(configuration.applicationId, session, viewHistory, actionContexts, urlContexts);
  return {
    addAction,
    addError,
    addTiming,
    addFeatureFlagEvaluation: featureFlagContexts.addFeatureFlagEvaluation,
    startView,
    setViewContext,
    setViewContextProperty,
    getViewContext,
    setViewName,
    lifeCycle,
    viewHistory,
    session,
    stopSession: () => session.expire(),
    getInternalContext: internalContext.get,
    startDurationVital: vitalCollection.startDurationVital,
    stopDurationVital: vitalCollection.stopDurationVital,
    addDurationVital: vitalCollection.addDurationVital,
    stop: () => {
      cleanupTasks.forEach(task => task());
    }
  };
}
function startRumTelemetry(configuration) {
  const telemetry = startTelemetry("browser-rum-sdk" /* TelemetryService.RUM */, configuration);
  if (canUseEventBridge()) {
    const bridge = getEventBridge();
    telemetry.observable.subscribe(event => bridge.send('internal_telemetry', event));
  }
  return telemetry;
}
export function startRumEventCollection(lifeCycle, configuration, location, sessionManager, pageStateHistory, locationChangeObservable, domMutationObservable, featureFlagContexts, windowOpenObservable, getCommonContext, reportError) {
  const viewHistory = startViewHistory(lifeCycle);
  const urlContexts = startUrlContexts(lifeCycle, locationChangeObservable, location);
  const actionCollection = startActionCollection(lifeCycle, domMutationObservable, windowOpenObservable, configuration, pageStateHistory);
  const displayContext = startDisplayContext(configuration);
  const ciVisibilityContext = startCiVisibilityContext(configuration);
  startRumAssembly(configuration, lifeCycle, sessionManager, viewHistory, urlContexts, actionCollection.actionContexts, displayContext, ciVisibilityContext, featureFlagContexts, getCommonContext, reportError);
  return {
    viewHistory,
    pageStateHistory,
    urlContexts,
    addAction: actionCollection.addAction,
    actionContexts: actionCollection.actionContexts,
    stop: () => {
      actionCollection.stop();
      ciVisibilityContext.stop();
      displayContext.stop();
      urlContexts.stop();
      viewHistory.stop();
      pageStateHistory.stop();
    }
  };
}
