import { clocksNow, combine, elapsed, generateUUID, toServerDuration } from '@datadog/browser-core';
export function createCustomVitalsState() {
  const vitalsByName = new Map();
  const vitalsByReference = new WeakMap();
  return {
    vitalsByName,
    vitalsByReference
  };
}
export function startVitalCollection(lifeCycle, pageStateHistory, customVitalsState) {
  function isValid(vital) {
    return !pageStateHistory.wasInPageStateDuringPeriod("frozen" /* PageState.FROZEN */, vital.startClocks.relative, vital.duration);
  }
  function addDurationVital(vital) {
    if (isValid(vital)) {
      lifeCycle.notify(12 /* LifeCycleEventType.RAW_RUM_EVENT_COLLECTED */, processVital(vital, true));
    }
  }
  return {
    addDurationVital,
    startDurationVital: (name, options = {}) => startDurationVital(customVitalsState, name, options),
    stopDurationVital: (nameOrRef, options = {}) => {
      stopDurationVital(addDurationVital, customVitalsState, nameOrRef, options);
    }
  };
}
export function startDurationVital({
  vitalsByName,
  vitalsByReference
}, name, options = {}) {
  const vital = {
    name,
    startClocks: clocksNow(),
    context: options.context,
    description: options.description
  };
  // To avoid leaking implementation details of the vital, we return a reference to it.
  const reference = {
    __dd_vital_reference: true
  };
  vitalsByName.set(name, vital);
  // To avoid memory leaks caused by the creation of numerous references (e.g., from improper useEffect implementations), we use a WeakMap.
  vitalsByReference.set(reference, vital);
  return reference;
}
export function stopDurationVital(stopCallback, {
  vitalsByName,
  vitalsByReference
}, nameOrRef, options = {}) {
  const vitalStart = typeof nameOrRef === 'string' ? vitalsByName.get(nameOrRef) : vitalsByReference.get(nameOrRef);
  if (!vitalStart) {
    return;
  }
  stopCallback(buildDurationVital(vitalStart, vitalStart.startClocks, options, clocksNow()));
  if (typeof nameOrRef === 'string') {
    vitalsByName.delete(nameOrRef);
  } else {
    vitalsByReference.delete(nameOrRef);
  }
}
function buildDurationVital(vitalStart, startClocks, stopOptions, stopClocks) {
  var _a;
  return {
    name: vitalStart.name,
    type: "duration" /* VitalType.DURATION */,
    startClocks,
    duration: elapsed(startClocks.timeStamp, stopClocks.timeStamp),
    context: combine(vitalStart.context, stopOptions.context),
    description: (_a = stopOptions.description) !== null && _a !== void 0 ? _a : vitalStart.description
  };
}
function processVital(vital, valueComputedBySdk) {
  const rawRumEvent = {
    date: vital.startClocks.timeStamp,
    vital: {
      id: generateUUID(),
      type: vital.type,
      name: vital.name,
      duration: toServerDuration(vital.duration),
      description: vital.description
    },
    type: "vital" /* RumEventType.VITAL */
  };
  if (valueComputedBySdk) {
    rawRumEvent._dd = {
      vital: {
        computed_value: true
      }
    };
  }
  return {
    rawRumEvent,
    startTime: vital.startClocks.relative,
    customerContext: vital.context,
    domainContext: {}
  };
}
