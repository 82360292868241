import { noop, PageExitReason, shallowClone, elapsed, generateUUID, ONE_MINUTE, throttle, clocksNow, clocksOrigin, timeStampNow, display, looksLikeRelativeTime, setInterval, clearInterval, setTimeout, Observable, createContextManager } from '@datadog/browser-core';
import { trackViewEventCounts } from './trackViewEventCounts';
import { trackInitialViewMetrics } from './viewMetrics/trackInitialViewMetrics';
import { trackCommonViewMetrics } from './viewMetrics/trackCommonViewMetrics';
export const THROTTLE_VIEW_UPDATE_PERIOD = 3000;
export const SESSION_KEEP_ALIVE_INTERVAL = 5 * ONE_MINUTE;
// Some events or metrics can be captured after the end of the view. To avoid missing those;
// an arbitrary delay is added for stopping their tracking after the view ends.
//
// Ideally, we would not stop and keep tracking events or metrics until the end of the session.
// But this might have a small performance impact if there are many many views.
// So let's have a fairly short delay improving the situation in most cases and avoid impacting performances too much.
export const KEEP_TRACKING_AFTER_VIEW_DELAY = 5 * ONE_MINUTE;
export function trackViews(location, lifeCycle, domMutationObservable, windowOpenObservable, configuration, locationChangeObservable, areViewsTrackedAutomatically, initialViewOptions) {
  const activeViews = new Set();
  let currentView = startNewView("initial_load" /* ViewLoadingType.INITIAL_LOAD */, clocksOrigin(), initialViewOptions);
  startViewLifeCycle();
  let locationChangeSubscription;
  if (areViewsTrackedAutomatically) {
    locationChangeSubscription = renewViewOnLocationChange(locationChangeObservable);
  }
  function startNewView(loadingType, startClocks, viewOptions) {
    const newlyCreatedView = newView(lifeCycle, domMutationObservable, windowOpenObservable, configuration, location, loadingType, startClocks, viewOptions);
    activeViews.add(newlyCreatedView);
    newlyCreatedView.stopObservable.subscribe(() => {
      activeViews.delete(newlyCreatedView);
    });
    return newlyCreatedView;
  }
  function startViewLifeCycle() {
    lifeCycle.subscribe(10 /* LifeCycleEventType.SESSION_RENEWED */, () => {
      // Renew view on session renewal
      currentView = startNewView("route_change" /* ViewLoadingType.ROUTE_CHANGE */, undefined, {
        name: currentView.name,
        service: currentView.service,
        version: currentView.version,
        context: currentView.contextManager.getContext()
      });
    });
    lifeCycle.subscribe(9 /* LifeCycleEventType.SESSION_EXPIRED */, () => {
      currentView.end({
        sessionIsActive: false
      });
    });
    // End the current view on page unload
    lifeCycle.subscribe(11 /* LifeCycleEventType.PAGE_EXITED */, pageExitEvent => {
      if (pageExitEvent.reason === PageExitReason.UNLOADING) {
        currentView.end();
      }
    });
  }
  function renewViewOnLocationChange(locationChangeObservable) {
    return locationChangeObservable.subscribe(({
      oldLocation,
      newLocation
    }) => {
      if (areDifferentLocation(oldLocation, newLocation)) {
        currentView.end();
        currentView = startNewView("route_change" /* ViewLoadingType.ROUTE_CHANGE */);
      }
    });
  }
  return {
    addTiming: (name, time = timeStampNow()) => {
      currentView.addTiming(name, time);
    },
    startView: (options, startClocks) => {
      currentView.end({
        endClocks: startClocks
      });
      currentView = startNewView("route_change" /* ViewLoadingType.ROUTE_CHANGE */, startClocks, options);
    },
    setViewContext: context => {
      currentView.contextManager.setContext(context);
    },
    setViewContextProperty: (key, value) => {
      currentView.contextManager.setContextProperty(key, value);
    },
    setViewName: name => {
      currentView.setViewName(name);
    },
    getViewContext: () => currentView.contextManager.getContext(),
    stop: () => {
      if (locationChangeSubscription) {
        locationChangeSubscription.unsubscribe();
      }
      currentView.end();
      activeViews.forEach(view => view.stop());
    }
  };
}
function newView(lifeCycle, domMutationObservable, windowOpenObservable, configuration, initialLocation, loadingType, startClocks = clocksNow(), viewOptions) {
  // Setup initial values
  const id = generateUUID();
  const stopObservable = new Observable();
  const customTimings = {};
  let documentVersion = 0;
  let endClocks;
  const location = shallowClone(initialLocation);
  const contextManager = createContextManager();
  let sessionIsActive = true;
  let name;
  let service;
  let version;
  let context;
  if (viewOptions) {
    name = viewOptions.name;
    service = viewOptions.service || undefined;
    version = viewOptions.version || undefined;
    if (viewOptions.context) {
      context = viewOptions.context;
      // use ContextManager to update the context so we always sanitize it
      contextManager.setContext(context);
    }
  }
  const viewCreatedEvent = {
    id,
    name,
    startClocks,
    service,
    version,
    context
  };
  lifeCycle.notify(1 /* LifeCycleEventType.BEFORE_VIEW_CREATED */, viewCreatedEvent);
  lifeCycle.notify(2 /* LifeCycleEventType.VIEW_CREATED */, viewCreatedEvent);
  // Update the view every time the measures are changing
  const {
    throttled,
    cancel: cancelScheduleViewUpdate
  } = throttle(triggerViewUpdate, THROTTLE_VIEW_UPDATE_PERIOD, {
    leading: false
  });
  const {
    setLoadEvent,
    setViewEnd,
    stop: stopCommonViewMetricsTracking,
    stopINPTracking,
    getCommonViewMetrics
  } = trackCommonViewMetrics(lifeCycle, domMutationObservable, windowOpenObservable, configuration, scheduleViewUpdate, loadingType, startClocks);
  const {
    stop: stopInitialViewMetricsTracking,
    initialViewMetrics
  } = loadingType === "initial_load" /* ViewLoadingType.INITIAL_LOAD */ ? trackInitialViewMetrics(configuration, setLoadEvent, scheduleViewUpdate) : {
    stop: noop,
    initialViewMetrics: {}
  };
  const {
    stop: stopEventCountsTracking,
    eventCounts
  } = trackViewEventCounts(lifeCycle, id, scheduleViewUpdate);
  // Session keep alive
  const keepAliveIntervalId = setInterval(triggerViewUpdate, SESSION_KEEP_ALIVE_INTERVAL);
  // Initial view update
  triggerViewUpdate();
  // View context update should always be throttled
  contextManager.changeObservable.subscribe(scheduleViewUpdate);
  function triggerBeforeViewUpdate() {
    lifeCycle.notify(3 /* LifeCycleEventType.BEFORE_VIEW_UPDATED */, {
      id,
      name,
      context: contextManager.getContext(),
      startClocks
    });
  }
  function scheduleViewUpdate() {
    triggerBeforeViewUpdate();
    throttled();
  }
  function triggerViewUpdate() {
    cancelScheduleViewUpdate();
    triggerBeforeViewUpdate();
    documentVersion += 1;
    const currentEnd = endClocks === undefined ? timeStampNow() : endClocks.timeStamp;
    lifeCycle.notify(4 /* LifeCycleEventType.VIEW_UPDATED */, {
      customTimings,
      documentVersion,
      id,
      name,
      service,
      version,
      context: contextManager.getContext(),
      loadingType,
      location,
      startClocks,
      commonViewMetrics: getCommonViewMetrics(),
      initialViewMetrics,
      duration: elapsed(startClocks.timeStamp, currentEnd),
      isActive: endClocks === undefined,
      sessionIsActive,
      eventCounts
    });
  }
  return {
    get name() {
      return name;
    },
    service,
    version,
    contextManager,
    stopObservable,
    end(options = {}) {
      var _a, _b;
      if (endClocks) {
        // view already ended
        return;
      }
      endClocks = (_a = options.endClocks) !== null && _a !== void 0 ? _a : clocksNow();
      sessionIsActive = (_b = options.sessionIsActive) !== null && _b !== void 0 ? _b : true;
      lifeCycle.notify(5 /* LifeCycleEventType.VIEW_ENDED */, {
        endClocks
      });
      lifeCycle.notify(6 /* LifeCycleEventType.AFTER_VIEW_ENDED */, {
        endClocks
      });
      clearInterval(keepAliveIntervalId);
      setViewEnd(endClocks.relative);
      stopCommonViewMetricsTracking();
      triggerViewUpdate();
      setTimeout(() => {
        this.stop();
      }, KEEP_TRACKING_AFTER_VIEW_DELAY);
    },
    stop() {
      stopInitialViewMetricsTracking();
      stopEventCountsTracking();
      stopINPTracking();
      stopObservable.notify();
    },
    addTiming(name, time) {
      if (endClocks) {
        return;
      }
      const relativeTime = looksLikeRelativeTime(time) ? time : elapsed(startClocks.timeStamp, time);
      customTimings[sanitizeTiming(name)] = relativeTime;
      scheduleViewUpdate();
    },
    setViewName(updatedName) {
      name = updatedName;
      triggerViewUpdate();
    }
  };
}
/**
 * Timing name is used as facet path that must contain only letters, digits, or the characters - _ . @ $
 */
function sanitizeTiming(name) {
  const sanitized = name.replace(/[^a-zA-Z0-9-_.@$]/g, '_');
  if (sanitized !== name) {
    display.warn(`Invalid timing name: ${name}, sanitized to: ${sanitized}`);
  }
  return sanitized;
}
function areDifferentLocation(currentLocation, otherLocation) {
  return currentLocation.pathname !== otherLocation.pathname || !isHashAnAnchor(otherLocation.hash) && getPathFromHash(otherLocation.hash) !== getPathFromHash(currentLocation.hash);
}
function isHashAnAnchor(hash) {
  const correspondingId = hash.substring(1);
  // check if the correspondingId is empty because on Firefox an empty string passed to getElementById() prints a consol warning
  return correspondingId !== '' && !!document.getElementById(correspondingId);
}
function getPathFromHash(hash) {
  const index = hash.indexOf('?');
  return index < 0 ? hash : hash.slice(0, index);
}
