import { Observable, bridgeSupports, noop, performDraw, startSessionManager } from '@datadog/browser-core';
export const RUM_SESSION_KEY = 'rum';
export function startRumSessionManager(configuration, lifeCycle, trackingConsentState) {
  const sessionManager = startSessionManager(configuration, RUM_SESSION_KEY, rawTrackingType => computeSessionState(configuration, rawTrackingType), trackingConsentState);
  sessionManager.expireObservable.subscribe(() => {
    lifeCycle.notify(9 /* LifeCycleEventType.SESSION_EXPIRED */);
  });
  sessionManager.renewObservable.subscribe(() => {
    lifeCycle.notify(10 /* LifeCycleEventType.SESSION_RENEWED */);
  });
  sessionManager.sessionStateUpdateObservable.subscribe(({
    previousState,
    newState
  }) => {
    if (!previousState.forcedReplay && newState.forcedReplay) {
      const sessionEntity = sessionManager.findSession();
      if (sessionEntity) {
        sessionEntity.isReplayForced = true;
      }
    }
  });
  return {
    findTrackedSession: startTime => {
      const session = sessionManager.findSession(startTime);
      if (!session || !isTypeTracked(session.trackingType)) {
        return;
      }
      return {
        id: session.id,
        sessionReplay: session.trackingType === "1" /* RumTrackingType.TRACKED_WITH_SESSION_REPLAY */ ? 1 /* SessionReplayState.SAMPLED */ : session.isReplayForced ? 2 /* SessionReplayState.FORCED */ : 0 /* SessionReplayState.OFF */,
        anonymousId: session.anonymousId
      };
    },
    expire: sessionManager.expire,
    expireObservable: sessionManager.expireObservable,
    setForcedReplay: () => sessionManager.updateSessionState({
      forcedReplay: '1'
    })
  };
}
/**
 * Start a tracked replay session stub
 */
export function startRumSessionManagerStub() {
  const session = {
    id: '00000000-aaaa-0000-aaaa-000000000000',
    sessionReplay: bridgeSupports("records" /* BridgeCapability.RECORDS */) ? 1 /* SessionReplayState.SAMPLED */ : 0 /* SessionReplayState.OFF */
  };
  return {
    findTrackedSession: () => session,
    expire: noop,
    expireObservable: new Observable(),
    setForcedReplay: noop
  };
}
function computeSessionState(configuration, rawTrackingType) {
  let trackingType;
  if (hasValidRumSession(rawTrackingType)) {
    trackingType = rawTrackingType;
  } else if (!performDraw(configuration.sessionSampleRate)) {
    trackingType = "0" /* RumTrackingType.NOT_TRACKED */;
  } else if (!performDraw(configuration.sessionReplaySampleRate)) {
    trackingType = "2" /* RumTrackingType.TRACKED_WITHOUT_SESSION_REPLAY */;
  } else {
    trackingType = "1" /* RumTrackingType.TRACKED_WITH_SESSION_REPLAY */;
  }
  return {
    trackingType,
    isTracked: isTypeTracked(trackingType)
  };
}
function hasValidRumSession(trackingType) {
  return trackingType === "0" /* RumTrackingType.NOT_TRACKED */ || trackingType === "1" /* RumTrackingType.TRACKED_WITH_SESSION_REPLAY */ || trackingType === "2" /* RumTrackingType.TRACKED_WITHOUT_SESSION_REPLAY */;
}
function isTypeTracked(rumSessionType) {
  return rumSessionType === "2" /* RumTrackingType.TRACKED_WITHOUT_SESSION_REPLAY */ || rumSessionType === "1" /* RumTrackingType.TRACKED_WITH_SESSION_REPLAY */;
}
