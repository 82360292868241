import { addTelemetryUsage, createContextManager, deepClone, makePublicApi, monitor, clocksNow, callMonitored, createHandlingStack, checkUser, sanitizeUser, sanitize, createIdentityEncoder, createCustomerDataTrackerManager, storeContextManager, displayAlreadyInitializedError, createTrackingConsentState, timeStampToClocks } from '@datadog/browser-core';
import { buildCommonContext } from '../domain/contexts/commonContext';
import { createCustomVitalsState } from '../domain/vital/vitalCollection';
import { callPluginsMethod } from '../domain/plugins';
import { createPreStartStrategy } from './preStartRum';
const RUM_STORAGE_KEY = 'rum';
export function makeRumPublicApi(startRumImpl, recorderApi, options = {}) {
  const customerDataTrackerManager = createCustomerDataTrackerManager(0 /* CustomerDataCompressionStatus.Unknown */);
  const globalContextManager = createContextManager(customerDataTrackerManager.getOrCreateTracker(2 /* CustomerDataType.GlobalContext */));
  const userContextManager = createContextManager(customerDataTrackerManager.getOrCreateTracker(1 /* CustomerDataType.User */));
  const trackingConsentState = createTrackingConsentState();
  const customVitalsState = createCustomVitalsState();
  function getCommonContext() {
    return buildCommonContext(globalContextManager, userContextManager, recorderApi);
  }
  let strategy = createPreStartStrategy(options, getCommonContext, trackingConsentState, customVitalsState, (configuration, deflateWorker, initialViewOptions) => {
    if (configuration.storeContextsAcrossPages) {
      storeContextManager(configuration, globalContextManager, RUM_STORAGE_KEY, 2 /* CustomerDataType.GlobalContext */);
      storeContextManager(configuration, userContextManager, RUM_STORAGE_KEY, 1 /* CustomerDataType.User */);
    }
    customerDataTrackerManager.setCompressionStatus(deflateWorker ? 1 /* CustomerDataCompressionStatus.Enabled */ : 2 /* CustomerDataCompressionStatus.Disabled */);
    const startRumResult = startRumImpl(configuration, recorderApi, customerDataTrackerManager, getCommonContext, initialViewOptions, deflateWorker && options.createDeflateEncoder ? streamId => options.createDeflateEncoder(configuration, deflateWorker, streamId) : createIdentityEncoder, trackingConsentState, customVitalsState);
    recorderApi.onRumStart(startRumResult.lifeCycle, configuration, startRumResult.session, startRumResult.viewHistory, deflateWorker);
    strategy = createPostStartStrategy(strategy, startRumResult);
    callPluginsMethod(configuration.plugins, 'onRumStart', {
      strategy
    });
    return startRumResult;
  });
  const startView = monitor(options => {
    const sanitizedOptions = typeof options === 'object' ? options : {
      name: options
    };
    if (sanitizedOptions.context) {
      customerDataTrackerManager.getOrCreateTracker(3 /* CustomerDataType.View */).updateCustomerData(sanitizedOptions.context);
    }
    strategy.startView(sanitizedOptions);
    addTelemetryUsage({
      feature: 'start-view'
    });
  });
  const rumPublicApi = makePublicApi({
    init: monitor(initConfiguration => {
      strategy.init(initConfiguration, rumPublicApi);
    }),
    setTrackingConsent: monitor(trackingConsent => {
      trackingConsentState.update(trackingConsent);
      addTelemetryUsage({
        feature: 'set-tracking-consent',
        tracking_consent: trackingConsent
      });
    }),
    setViewName: monitor(name => {
      strategy.setViewName(name);
      addTelemetryUsage({
        feature: 'set-view-name'
      });
    }),
    setViewContext: monitor(context => {
      strategy.setViewContext(context);
      addTelemetryUsage({
        feature: 'set-view-context'
      });
    }),
    setViewContextProperty: monitor((key, value) => {
      strategy.setViewContextProperty(key, value);
      addTelemetryUsage({
        feature: 'set-view-context-property'
      });
    }),
    getViewContext: monitor(() => {
      addTelemetryUsage({
        feature: 'set-view-context-property'
      });
      return strategy.getViewContext();
    }),
    setGlobalContext: monitor(context => {
      globalContextManager.setContext(context);
      addTelemetryUsage({
        feature: 'set-global-context'
      });
    }),
    getGlobalContext: monitor(() => globalContextManager.getContext()),
    setGlobalContextProperty: monitor((key, value) => {
      globalContextManager.setContextProperty(key, value);
      addTelemetryUsage({
        feature: 'set-global-context'
      });
    }),
    removeGlobalContextProperty: monitor(key => globalContextManager.removeContextProperty(key)),
    clearGlobalContext: monitor(() => globalContextManager.clearContext()),
    getInternalContext: monitor(startTime => strategy.getInternalContext(startTime)),
    getInitConfiguration: monitor(() => deepClone(strategy.initConfiguration)),
    addAction: (name, context) => {
      const handlingStack = createHandlingStack();
      callMonitored(() => {
        strategy.addAction({
          name: sanitize(name),
          context: sanitize(context),
          startClocks: clocksNow(),
          type: "custom" /* ActionType.CUSTOM */,
          handlingStack
        });
        addTelemetryUsage({
          feature: 'add-action'
        });
      });
    },
    addError: (error, context) => {
      const handlingStack = createHandlingStack();
      callMonitored(() => {
        strategy.addError({
          error,
          // Do not sanitize error here, it is needed unserialized by computeRawError()
          handlingStack,
          context: sanitize(context),
          startClocks: clocksNow()
        });
        addTelemetryUsage({
          feature: 'add-error'
        });
      });
    },
    addTiming: monitor((name, time) => {
      // TODO: next major decide to drop relative time support or update its behaviour
      strategy.addTiming(sanitize(name), time);
    }),
    setUser: monitor(newUser => {
      if (checkUser(newUser)) {
        userContextManager.setContext(sanitizeUser(newUser));
      }
      addTelemetryUsage({
        feature: 'set-user'
      });
    }),
    getUser: monitor(() => userContextManager.getContext()),
    setUserProperty: monitor((key, property) => {
      const sanitizedProperty = sanitizeUser({
        [key]: property
      })[key];
      userContextManager.setContextProperty(key, sanitizedProperty);
      addTelemetryUsage({
        feature: 'set-user'
      });
    }),
    removeUserProperty: monitor(key => userContextManager.removeContextProperty(key)),
    clearUser: monitor(() => userContextManager.clearContext()),
    startView,
    stopSession: monitor(() => {
      strategy.stopSession();
      addTelemetryUsage({
        feature: 'stop-session'
      });
    }),
    addFeatureFlagEvaluation: monitor((key, value) => {
      strategy.addFeatureFlagEvaluation(sanitize(key), sanitize(value));
      addTelemetryUsage({
        feature: 'add-feature-flag-evaluation'
      });
    }),
    getSessionReplayLink: monitor(() => recorderApi.getSessionReplayLink()),
    startSessionReplayRecording: monitor(options => {
      recorderApi.start(options);
      addTelemetryUsage({
        feature: 'start-session-replay-recording',
        force: options && options.force
      });
    }),
    stopSessionReplayRecording: monitor(() => recorderApi.stop()),
    addDurationVital: monitor((name, options) => {
      addTelemetryUsage({
        feature: 'add-duration-vital'
      });
      strategy.addDurationVital({
        name: sanitize(name),
        type: "duration" /* VitalType.DURATION */,
        startClocks: timeStampToClocks(options.startTime),
        duration: options.duration,
        context: sanitize(options && options.context),
        description: sanitize(options && options.description)
      });
    }),
    startDurationVital: monitor((name, options) => {
      addTelemetryUsage({
        feature: 'start-duration-vital'
      });
      return strategy.startDurationVital(sanitize(name), {
        context: sanitize(options && options.context),
        description: sanitize(options && options.description)
      });
    }),
    stopDurationVital: monitor((nameOrRef, options) => {
      addTelemetryUsage({
        feature: 'stop-duration-vital'
      });
      strategy.stopDurationVital(typeof nameOrRef === 'string' ? sanitize(nameOrRef) : nameOrRef, {
        context: sanitize(options && options.context),
        description: sanitize(options && options.description)
      });
    })
  });
  return rumPublicApi;
}
function createPostStartStrategy(preStartStrategy, startRumResult) {
  return {
    init: initConfiguration => {
      displayAlreadyInitializedError('DD_RUM', initConfiguration);
    },
    initConfiguration: preStartStrategy.initConfiguration,
    ...startRumResult
  };
}
