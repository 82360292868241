import { display, addEventListener, buildEndpointHost } from '@datadog/browser-core';
const REMOTE_CONFIGURATION_VERSION = 'v1';
export function fetchAndApplyRemoteConfiguration(initConfiguration, callback) {
  fetchRemoteConfiguration(initConfiguration, remoteInitConfiguration => {
    callback(applyRemoteConfiguration(initConfiguration, remoteInitConfiguration));
  });
}
export function applyRemoteConfiguration(initConfiguration, remoteInitConfiguration) {
  return {
    ...initConfiguration,
    ...remoteInitConfiguration
  };
}
export function fetchRemoteConfiguration(configuration, callback) {
  const xhr = new XMLHttpRequest();
  addEventListener(configuration, xhr, 'load', function () {
    if (xhr.status === 200) {
      const remoteConfiguration = JSON.parse(xhr.responseText);
      callback(remoteConfiguration.rum);
    } else {
      displayRemoteConfigurationFetchingError();
    }
  });
  addEventListener(configuration, xhr, 'error', function () {
    displayRemoteConfigurationFetchingError();
  });
  xhr.open('GET', buildEndpoint(configuration));
  xhr.send();
}
export function buildEndpoint(configuration) {
  return `https://sdk-configuration.${buildEndpointHost('rum', configuration)}/${REMOTE_CONFIGURATION_VERSION}/${encodeURIComponent(configuration.remoteConfigurationId)}.json`;
}
function displayRemoteConfigurationFetchingError() {
  display.error('Error fetching the remote configuration.');
}
