import { ErrorSource, generateUUID, computeRawError, computeStackTrace, Observable, trackRuntimeError, isError } from '@datadog/browser-core';
import { trackConsoleError } from './trackConsoleError';
import { trackReportError } from './trackReportError';
export function startErrorCollection(lifeCycle, configuration, pageStateHistory) {
  const errorObservable = new Observable();
  trackConsoleError(errorObservable);
  trackRuntimeError(errorObservable);
  trackReportError(configuration, errorObservable);
  errorObservable.subscribe(error => lifeCycle.notify(14 /* LifeCycleEventType.RAW_ERROR_COLLECTED */, {
    error
  }));
  return doStartErrorCollection(lifeCycle, pageStateHistory);
}
export function doStartErrorCollection(lifeCycle, pageStateHistory) {
  lifeCycle.subscribe(14 /* LifeCycleEventType.RAW_ERROR_COLLECTED */, ({
    error,
    customerContext,
    savedCommonContext
  }) => {
    lifeCycle.notify(12 /* LifeCycleEventType.RAW_RUM_EVENT_COLLECTED */, {
      customerContext,
      savedCommonContext,
      ...processError(error, pageStateHistory)
    });
  });
  return {
    addError: ({
      error,
      handlingStack,
      componentStack,
      startClocks,
      context: customerContext
    }, savedCommonContext) => {
      const stackTrace = isError(error) ? computeStackTrace(error) : undefined;
      const rawError = computeRawError({
        stackTrace,
        originalError: error,
        handlingStack,
        componentStack,
        startClocks,
        nonErrorPrefix: "Provided" /* NonErrorPrefix.PROVIDED */,
        source: ErrorSource.CUSTOM,
        handling: "handled" /* ErrorHandling.HANDLED */
      });
      lifeCycle.notify(14 /* LifeCycleEventType.RAW_ERROR_COLLECTED */, {
        customerContext,
        savedCommonContext,
        error: rawError
      });
    }
  };
}
function processError(error, pageStateHistory) {
  const rawRumEvent = {
    date: error.startClocks.timeStamp,
    error: {
      id: generateUUID(),
      message: error.message,
      source: error.source,
      stack: error.stack,
      handling_stack: error.handlingStack,
      component_stack: error.componentStack,
      type: error.type,
      handling: error.handling,
      causes: error.causes,
      source_type: 'browser',
      fingerprint: error.fingerprint,
      csp: error.csp
    },
    type: "error" /* RumEventType.ERROR */,
    view: {
      in_foreground: pageStateHistory.wasInPageStateAt("active" /* PageState.ACTIVE */, error.startClocks.relative)
    }
  };
  const domainContext = {
    error: error.originalError,
    handlingStack: error.handlingStack
  };
  return {
    rawRumEvent,
    startTime: error.startClocks.relative,
    domainContext
  };
}
